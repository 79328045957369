import * as utilCommon from 'helpers/util-common';
import lodash from 'lodash';
import { AppGridIds, GridColumns, McsStatusMasterId, OfficerTitleEnum } from 'models/common.models';
import { CommitteeRosterFilter } from 'modules/committee-management/committee.model';
import moment from 'moment';
import { PendingNovoteReasonID, NoVoteReasonIdForEmpty } from '../../../../models/common.models';
let self = {};
let checkValue = 0;
let updatedDetailsForupdateMemberCommitteeBasicDetails = {};
let searchParam = {
    gridid: AppGridIds.CommitteeRosterGrid, CommitteeId: 0, Search: null, MemberTypeId: null, ClassificationId: null, Vote: null, Status: null, ShowOfficer: null,
    SortBy: GridColumns.MemberDetail.AccountNumber, SortAction: "asc", pagesize: 25, pagenumber: 1
}

export const setClassInstance = (instance) => {
    self = instance;
    searchParam.CommitteeId = self.props.initialValues.CommitteeId;
    loadInitialData();
}
export const resetGridTableData = () => {
    self.props.getCommitteeGridPreferences(AppGridIds.CommitteeRosterGrid, (tablePreferences) => {
        const searchData = utilCommon.deepCopy(searchParam);
        searchData.Status = searchData.Status ? searchData.Status : McsStatusMasterId.ActiveCommitteeMember;
        self.props.getRosterCommitteeMemberList(searchData, (response) => {
            handleCommitteeListDataResponse(response, tablePreferences, searchParam.pagesize, searchParam.pagenumber);
            self.setState({
                tableHeaderConfig: tablePreferences, isEditMode: false
            })
        })
    });
}
export const saveGridPreferenceClickAction = () => {
    const { gridProp } = self.state;
    self.setState({ isShowGridMenu: false });
    self.props.updateGridPreference(AppGridIds.CommitteeRosterGrid, gridProp.requestSaveParams, (result) => {
        if (result) {
            self.setState({ isShowGridMenu: true }, () => resetGridTableData());
        } else {
            self.setState({ isShowGridMenu: false });
        }
    });
}

export const onEditChange = (type, value) => {
    let { committeeUpdateModel } = self.state;
    let count = 0;

    switch (type) {
        case 'officer':
            const result = self.props.officerTitleList.find((filterResult) => {
                return filterResult.value === value;
            });
            if (result) {
                self.setState({ NewOfficerTitleName: result.text });
                committeeUpdateModel.MemberDetail_OfficerTitleName = result.text;
            }
            committeeUpdateModel.MemberDetail_OfficerTitleId = value;
            break;
        case 'classification':
            const matchedItem = self.props.classificationList.find((filterResult) => {
                return filterResult.value === value;
            });

            if (matchedItem) {
                committeeUpdateModel.MemberDetail_Classification = matchedItem.text;
            }
            committeeUpdateModel.MemberDetail_ClassificationTypeId = value;
            committeeUpdateModel.MemberDetail_ClassificationDate = utilCommon.formatDateToMMDDYYYY();
            break;
        case 'vote':
            committeeUpdateModel.MemberDetail_Vote = value;
            committeeUpdateModel.MemberDetail_VoteDate = utilCommon.formatDateToMMDDYYYY();
            break;
        case 'voteReason':
            committeeUpdateModel.MemberDetail_NoVoteReasonId = value;
            if (value && (value > 0 && value !== ' ') && !committeeUpdateModel.MemberDetail_Vote) {
                committeeUpdateModel.NoVoteReasonError = false;

            } else {
                committeeUpdateModel.NoVoteReasonError = true;

            }
            committeeUpdateModel.MemberDetail_VoteDate = utilCommon.formatDateToMMDDYYYY();
            break;
        case 'voteDate':
            committeeUpdateModel.MemberDetail_VoteDate = value;
            break;
        case 'joinDate':
            committeeUpdateModel.MemberDetail_JoinedDate = value;
            if (utilCommon.compareDates(committeeUpdateModel.MemberDetail_JoinedDate, committeeUpdateModel.MemberDetail_ClassificationDate)) {
                committeeUpdateModel.MemberDetail_ClassificationDate = value;
            }
            if (utilCommon.compareDates(committeeUpdateModel.MemberDetail_JoinedDate, committeeUpdateModel.MemberDetail_VoteDate)) {
                committeeUpdateModel.MemberDetail_VoteDate = value;
            }
            break
        case 'classificationDate':
            committeeUpdateModel.MemberDetail_ClassificationDate = value;
            break;
        case 'reason':
            committeeUpdateModel.UpdateReason = value;
            break;
        default:
            committeeUpdateModel = value ? utilCommon.deepCopy(value) : {};
            count = 1;
            if (!self.state.IsMainCommittee && committeeUpdateModel && committeeUpdateModel.MemberDetail_AccountNumber) {
                self.props.validateProducerClassification(self.props.initialValues.CommitteeId, committeeUpdateModel.MemberDetail_AccountNumber, (response) => {
                    committeeUpdateModel.CommitteeDetail_CommitteeId = self.props.initialValues.CommitteeId;
                    committeeUpdateModel.MemberDetail_Vote = committeeUpdateModel.MemberDetail_Vote ? 1 : 0;
                    committeeUpdateModel.MemberDetail_NoVoteReasonId = committeeUpdateModel.MemberDetail_Vote ? 1 : committeeUpdateModel.MemberDetail_NoVoteReasonId;
                    committeeUpdateModel.MemberDetail_JoinedDate = utilCommon.formatDateToMMDDYYYY(committeeUpdateModel.MemberDetail_JoinedDate);
                    committeeUpdateModel.MemberDetail_ClassificationDate = utilCommon.formatDateToMMDDYYYY(committeeUpdateModel.MemberDetail_ClassificationDate);
                    committeeUpdateModel.MemberDetail_VoteDate = utilCommon.formatDateToMMDDYYYY(committeeUpdateModel.MemberDetail_VoteDate);
                    committeeUpdateModel.NoVoteReasonError = false;
                    self.setState({ committeeUpdateModel, isEditMode: true, validateProducerClassification: response });
                });

            } else if (committeeUpdateModel && committeeUpdateModel.MemberDetail_AccountNumber) {
                committeeUpdateModel.CommitteeDetail_CommitteeId = self.props.initialValues.CommitteeId;
                committeeUpdateModel.MemberDetail_Vote = committeeUpdateModel.MemberDetail_Vote ? 1 : 0;
                committeeUpdateModel.MemberDetail_JoinedDate = utilCommon.formatDateToMMDDYYYY(committeeUpdateModel.MemberDetail_JoinedDate);
                committeeUpdateModel.MemberDetail_ClassificationDate = utilCommon.formatDateToMMDDYYYY(committeeUpdateModel.MemberDetail_ClassificationDate);
                committeeUpdateModel.MemberDetail_VoteDate = utilCommon.formatDateToMMDDYYYY(committeeUpdateModel.MemberDetail_VoteDate);
                committeeUpdateModel.NoVoteReasonError = false;
                self.setState({ committeeUpdateModel, isEditMode: true });
            } else {
                self.setState({ committeeUpdateModel, isEditMode: false });
            }
            break;
    }
    if (count === 0) { self.setState({ committeeUpdateModel, isEditMode: true }) }
}

export const cancelUpdateReason = () => {
    self.setState({ showUpdateReason: false, isEditMode: false });
}

export const popUpCancel = () => {
    self.setState({ isUpdatePopUp: false, showUpdateReason: false, isEditMode: false }, () => { loadInitialData(false); })
}

export const updatePopupCancel = () => {
    self.setState({ isUpdatePopUp: false });
}

export const popUpConfirm = () => {
    self.setState({ isUpdatePopUp: false, showUpdateReason: true });
}

export const confirmAdministrativeAssistantOrStaffManagerPopup = (event, value) => {
    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });
    // Updating Member Committee Basic Details
    OnUpdateRecored(updatedDetailsForupdateMemberCommitteeBasicDetails);
}

export const OnUpdateRecored = (value) => {
    const { gridProp, committeeUpdateModel } = self.state;
    if(committeeUpdateModel.MemberDetail_NoVoteReasonId === NoVoteReasonIdForEmpty && committeeUpdateModel.MemberDetail_NoVoteReason ===''){
        committeeUpdateModel.MemberDetail_NoVoteReasonId= PendingNovoteReasonID
        committeeUpdateModel.MemberDetail_NoVoteReason='Pending'
    }
    let officerTitleSelectedValue = (gridProp && gridProp.records ? gridProp.records.filter(x => x.isRowEdit == true)[0].MemberDetail_OfficerTitleName : '')
    let committeeUpdateModelEntity = committeeUpdateModel;
    if (value && value.UpdateReason) {
        self.setState({ showUpdateReason: false })
        const { initialValues: { CommitteeDesignation } } = self.props
        const { NewOfficerTitleName } = self.state
        var CommitteeDesignations = CommitteeDesignation;
        updatedDetailsForupdateMemberCommitteeBasicDetails = value;
        if ((NewOfficerTitleName == OfficerTitleEnum.StaffManager || NewOfficerTitleName == OfficerTitleEnum.AdministrativeAssistant) && CommitteeDesignations.indexOf('.') > -1 && checkValue == 0) {
            checkValue = 1;
            self.setState({
                isShowAdministrativeAssistantOrStaffManagerPopup: true,
                committeeName: CommitteeDesignations && CommitteeDesignations.split('.')[0]
            });
        }
        else {
            self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });
            checkValue = 0;
            committeeUpdateModel.UpdateReason = value.UpdateReason;
            committeeUpdateModel.MemberDetail_NoVoteReason = committeeUpdateModel.MemberDetail_NoVoteReasonId;
            committeeUpdateModel.MemberDetail_Vote = committeeUpdateModel.MemberDetail_Vote === 1;
            committeeUpdateModel.NewOfficerTitleName = self.state.NewOfficerTitleName === undefined ? committeeUpdateModel.MemberDetail_OfficerTitleName : self.state.NewOfficerTitleName;
            committeeUpdateModel.OfficerTitleSelectedValue = officerTitleSelectedValue; //need to check
            committeeUpdateModel.CommitteeDesignation = (CommitteeDesignations && CommitteeDesignations.split('.')[0] ? CommitteeDesignations.split('.')[0] : CommitteeDesignations);
            committeeUpdateModel.SubCommitteeDesignation = [(CommitteeDesignations && CommitteeDesignations.split('.')[0] ? CommitteeDesignations : '')];
            self.props.postCommitteeRoster(committeeUpdateModel, (response) => {

                // Replace the edited object with updated info
                if (gridProp.records) {
                    if (response !== undefined && response.message === 3025) {
                        self.setState({ isShowAdministrativeAssistantPopup: true });
                    } else {
                        const updatedItemIndex = gridProp.records.findIndex(i => i.MemberDetail_MemberId === committeeUpdateModel.MemberDetail_MemberId);

                        gridProp.records[updatedItemIndex].isRowEdit = false;
                        gridProp.records[updatedItemIndex] = {
                            ...gridProp.records[updatedItemIndex],
                            MemberDetail_OfficerTitleName: committeeUpdateModel.MemberDetail_OfficerTitleName,
                            MemberDetail_OfficerTitleId: committeeUpdateModel.MemberDetail_OfficerTitleId,
                            MemberDetail_Classification: committeeUpdateModel.MemberDetail_Classification,
                            MemberDetail_ClassificationTypeId: committeeUpdateModel.MemberDetail_ClassificationTypeId,
                            MemberDetail_ClassificationDate: committeeUpdateModel.MemberDetail_ClassificationDate,
                            MemberDetail_Vote: committeeUpdateModel.MemberDetail_Vote ? 1 : 0,
                            MemberDetail_NoVoteReason: committeeUpdateModel.MemberDetail_Vote ? '' : getNoVoteReasonText(committeeUpdateModel.MemberDetail_NoVoteReasonId),
                            MemberDetail_NoVoteReasonId: committeeUpdateModel.MemberDetail_NoVoteReasonId,
                            MemberDetail_VoteDate: committeeUpdateModel.MemberDetail_VoteDate,
                            MemberDetail_JoinedDate: committeeUpdateModel.MemberDetail_JoinedDate
                        }
                    }
                }
                if (response === undefined || response !== undefined && response.message !== 3025) {
                    self.setState({ isEditMode: false, gridProp });
                    refreshCommitteeDetails();
                }
            })
        }
    } else if (!self.state.IsMainCommittee && self.props.initialValues.CommitteeId > 0 &&
        self.props.classificationList.filter(item => item.value === self.state.committeeUpdateModel.MemberDetail_ClassificationTypeId && item.text.toLowerCase() === 'producer').length > 0 &&
        self.state.validateProducerClassification && self.props.initialValues.LevelId === 2) {
        let isUpdatePopUp = true;

        if (committeeUpdateModelEntity && (!self.state.committeeUpdateModel.MemberDetail_Vote && (committeeUpdateModelEntity.NoVoteReasonId <= 0 || committeeUpdateModelEntity.NoVoteReasonId === ''))) {
            committeeUpdateModelEntity.NoVoteReasonError = true;
            isUpdatePopUp = false;
        } else {
            committeeUpdateModelEntity.NoVoteReasonError = false;
        }

        self.setState({ isUpdatePopUp, committeeUpdateModel: committeeUpdateModelEntity })
    } else if (committeeUpdateModelEntity && (!self.state.committeeUpdateModel.MemberDetail_Vote && (committeeUpdateModelEntity.NoVoteReasonId <= 0 || committeeUpdateModelEntity.NoVoteReasonId === ''))) {
        committeeUpdateModelEntity.NoVoteReasonError = true;
        self.setState({ showUpdateReason: false, committeeUpdateModel: committeeUpdateModelEntity });
    } else {
        committeeUpdateModelEntity.NoVoteReasonError = false;
        self.setState({ showUpdateReason: true, committeeUpdateModel: committeeUpdateModelEntity });
    }
}

const getNoVoteReasonText = (voteId) => {
    const { noVoteReasonList } = self.props;

    if (noVoteReasonList && noVoteReasonList.length > 0) {
        const matchedItem = noVoteReasonList.find(i => i.key === voteId);

        return matchedItem ? matchedItem.text : '';
    }

    return '';
}

const validateFilterFromCommitteeDetails = () => {
    const { selectedFilters } = self.props;
    return receivedCommitteeClassificationStatisticalListInformation(selectedFilters);

}
const loadInitialData = (isFilterNeededFromCommitteeDetails = true) => {
    if (isFilterNeededFromCommitteeDetails) {
        resetFilter(validateFilterFromCommitteeDetails());
    } else {
        resetFilter();
    }

    loadData(true);
    self.setState({ isTableHeaderReset: true, showUpdateReason: false, isOfficerOnly: false });
}
export const loadData = (showPageLoader = false) => {
    const { committeeRosterGridPreference } = self.props;
    const searchData = utilCommon.deepCopy(searchParam);
    searchData.Status = searchData.Status ? searchData.Status : McsStatusMasterId.ActiveCommitteeMember;

    if (!showPageLoader) {
        self.setState({ showLoader: true });
    }
    if (self.props.membershipTypeList && self.props.membershipTypeList.length === 0) {
        self.props.getMembershipTypeList();
    }

    if (self.props.classificationList && self.props.classificationList.length === 0) {
        self.props.getCommitteeClassificationTypeAndNoVoteReason(self.props.initialValues.CommitteeId);
    }

    // If grid preferences alerady exist, then just get the table data
    if (committeeRosterGridPreference && committeeRosterGridPreference.length > 0) {
        self.props.getRosterCommitteeMemberList(searchData, (response) => {

            handleCommitteeListDataResponse(response, committeeRosterGridPreference, searchData.pagesize, searchData.pagenumber);
            self.setState({
                tableHeaderConfig: committeeRosterGridPreference, isEditMode: false, showLoader: false
            });
        })

    } else {
        self.props.getCommitteeGridPreferences(AppGridIds.CommitteeRosterGrid, (tablePreferences) => {
            self.props.getRosterCommitteeMemberList(searchData, (response) => {
                handleCommitteeListDataResponse(response, tablePreferences, searchData.pagesize, searchData.pagenumber);

                self.setState({
                    tableHeaderConfig: tablePreferences, isEditMode: false, showLoader: false
                })
            })
        })
    }
}
export const onChangeNumberOfItemsPerPage = (e, value) => {
    searchParam.pagesize = value;
    searchParam.pagenumber = 1;
    loadData();
    self.setState({ activePageNumber: 1, isTableHeaderReset: false, pageSize: value, isEditMode: false, showUpdateReason: false });
}

export const onPageChangeHandler = (event, data) => {
    const { totalPages } = self.state;

    if (totalPages > 1) {
        searchParam.pagenumber = data.activePage;
        loadData();
    }

    self.setState({ activePageNumber: data.activePage, isTableHeaderReset: false, isEditMode: false, showUpdateReason: false })
}
const handleCommitteeListDataResponse = (committeeData, gridHeader, pageSize, pageNumber) => {
    let committeeList = [];
    let count = 0;
    if (committeeData && committeeData.Count && committeeData.CommitteeMembers) {
        committeeList = committeeData.CommitteeMembers;

        if (committeeList && committeeList.length > 0) {
            committeeList = committeeList.map(item => {
                item.isRowEdit = false;
                item.MemberDetail_Vote = lodash(item).get(GridColumns.MemberDetail.Vote) ? 1 : 0;
                return item
            })
        }
        count = committeeData.Count;
        self.state.IsMainCommittee = committeeData.IsMainCommittee;
    }

    if (count > pageSize) {
        self.state.showPager = true;
    }
    self.setState({ totalCountForExport: count })
    setAllGridPreference(committeeList, gridHeader, count);
    updateCurrentlyShowingItems(count, pageSize, pageNumber);
    // Whenever data is refreshed, Move the scroll to top.
    utilCommon.scrollToTopOfPage();
}
const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
    let totalPages = Math.ceil(totalItems / pageSize);
    const currentlyShowingItems = utilCommon.getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);
    self.setState({ currentlyShowingItems, totalPages });
}
export const onChangeSortHandler = (sortedColumn, sortOrder) => {
    searchParam.SortBy = sortedColumn;
    let activeSortColumn = self.state.activeSortColumn;
    activeSortColumn.name = sortedColumn;
    activeSortColumn.orderByIcon = sortOrder
    if (sortOrder === 1 || sortOrder === 2) {
        searchParam.SortAction = sortOrder === 1 ? 'asc' : 'desc';
    }

    self.setState({ SortBy: sortedColumn, SortAction: sortOrder, isTableHeaderReset: false, activeSortColumn });

    // Load new data
    loadData();
}
const setAllGridPreference = (committeeList, gridHeader, count) => {
    const { gridProp, isFilterChanged } = self.state;
    let columnKeys = [];
    if (self.state.isTableHeaderReset) { // Worked only isTableHeaderReset = true;
        // Set empty object for set all the grid table values
        let tableConfigProps = {
            attributes: {}, displayName: {}, expandables: [], sortables: {}, omitOnMenu: ['Action'], excelExportedColumn: [], emptyMessage: utilCommon.checkIfUserDidAdvanceSearch(self.props.location.search) || isFilterChanged ? utilCommon.getMessage(self.props.messageCodes, '9013.text') : utilCommon.getMessage(self.props.messageCodes, '9010.text'), records: committeeList, excelExportableFieldsCallback: excelExportableFieldsCallback
            , editableGridHeader: {}, allowTooltip: true
        }

        utilCommon.updateGridProps(gridHeader, tableConfigProps, columnKeys);
        self.props.committeeRosterGridPreference.map(res => {
            if (res.IsEditable) {
                tableConfigProps.editableGridHeader[res.FieldName] = res.IsEditable;
                tableConfigProps.sortables[res.FieldName] = res.IsSortable;
                tableConfigProps.displayName[res.FieldName] = res.DisplayName
            }
        })

        self.setState({
            isTableHeaderReset: false,
            showLoader: false,
            totalItems: count,
            isDataUpdated: true,
            gridProp: tableConfigProps
        }, () => setGridPreferenceParams(columnKeys)); // Get visible columns in grid section
    } else {
        // Update only grid records
        gridProp.records = committeeList;
        utilCommon.updateGridProps(gridHeader, gridProp, columnKeys);
        gridProp.emptyMessage = utilCommon.checkIfUserDidAdvanceSearch(self.props.location.search) || isFilterChanged ? utilCommon.getMessage(self.props.messageCodes, '9013.text') : utilCommon.getMessage(self.props.messageCodes, '9011.text');
        self.setState({
            showLoader: false,
            totalItems: count,
            isDataUpdated: true,
            gridProp
        }, () => setGridPreferenceParams(columnKeys)); // Get visible columns in grid section
    }
}
const setGridPreferenceParams = (params) => {
    const { gridProp } = self.state;
    let gridConfigParams = { "Columns": params };
    gridProp.requestSaveParams = gridConfigParams;
    self.setState({
        gridProp
    })
}
const excelExportableFieldsCallback = (fields) => {
    let exportFields = {};
    let column = [];
    let columnsKeys = [];

    const { gridProp } = self.state;
    if (fields !== null) {
        for (let keys in fields) {
            if (fields[keys]) {
                columnsKeys.push(keys);
                exportFields[keys] = fields[keys];
                column.push({ title: gridProp.displayName[keys], fieldsKey: keys, style: { font: { bold: true } } })
            }
        }
        gridProp.excelExportedColumn = column;
        self.setState({
            gridProp
        }, () => setGridPreferenceParams(utilCommon.validateExportableFields(self.state.tableHeaderConfig, columnsKeys)))
    }
}

export const openAddMemberToCommitteeModal = () => {
    self.setState({ showAddMemberCommitteeModal: true })
    self.props.getMembershipTypeList()
}

export const closeAddMemberToCommitteeModal = () => {
    self.setState({ showAddMemberCommitteeModal: false })
    loadInitialData(false);
}

export const onChangeFilter = (value, type) => {
    switch (type) {
        case CommitteeRosterFilter.MemberType:
            searchParam.MemberTypeId = value === 0 ? '' : value;
            self.setState({ membershipTypeSelectedValue: value });
            break;
        case CommitteeRosterFilter.Classification:
            searchParam.ClassificationId = value === 0 ? '' : value;
            self.setState({ classificationSelectedValue: value })
            break;
        case CommitteeRosterFilter.Vote:
            searchParam.Vote = value;
            self.setState({ voteSelectedValue: value });
            break;
        case CommitteeRosterFilter.Status:
            searchParam.Status = value;
            self.setState({ statusSelectedValue: value });
            break;
        default:
            loadInitialData(false);
            break;
    }
}
const resetFilter = (filters = null) => {
    self.setState({ membershipTypeSelectedValue: '', classificationSelectedValue: '', voteSelectedValue: '', statusSelectedValue: `${McsStatusMasterId.ActiveCommitteeMember}`, isOfficerOnly: false, activePageNumber: 1, pageSize: 25 });
    searchParam.pagesize = 25;
    searchParam.pagenumber = 1;
    searchParam.Status = (lodash(filters).get('Status', null) !== null && lodash(filters).get('Status', null) !== '') ? filters.Status : `${McsStatusMasterId.ActiveCommitteeMember}`;
    searchParam.Vote = lodash(filters).get('Vote', null) !== null ? filters.Vote : '';
    searchParam.ClassificationId = lodash(filters).get('ClassificationId', null) !== null ? filters.ClassificationId : '';
    searchParam.MemberTypeId = '';
    searchParam.ShowOfficer = '';
    searchParam.Search = '';
    if (document.getElementsByName('Member').length > 0) { document.getElementsByName('Member')[0].value = ''; }
}

export const searchFilterRecord = () => {
    searchParam.pagesize = 25;
    searchParam.pagenumber = 1;
    self.setState({ activePageNumber: 1, pageSize: 25 });
    searchParam.Search = document.getElementsByName('Member')[0].value
    loadData();
}

export const onEnterSearchFilterRecord = (event) => {
    if (event.key === 'Enter') {
        searchParam.Search = event.target.value.trim() || undefined
        searchParam.pagenumber = 1;
        searchParam.pagesize = 25;
        self.setState({ activePageNumber: 1, pageSize: 25 })
        loadData();
    }
}
export const OnChangeOfficerOnly = () => {
    let isOfficerOnly = !self.state.isOfficerOnly;
    searchParam.ShowOfficer = isOfficerOnly;
    self.setState({ isOfficerOnly })
}

// Start work on Excel export part :-
const validate = (value) => {
    return value ? value : '';
}
export const excelExport = () => {
    self.setState({ showLoader: true });
    searchParam.pagesize = self.state.totalCountForExport === 0 ? 25 : self.state.totalCountForExport;

    self.props.getRosterCommitteeMemberList(searchParam, (response) => {
        const officerTitleIds = []

        response.CommitteeMembers.filter(item => { item.MemberDetail_OfficerTitleId && officerTitleIds.push(item.MemberDetail_OfficerTitleId) })
        let uniqueOfficerTitleIds = (uniqueOfficerTitle) => uniqueOfficerTitle.filter((item, index) => uniqueOfficerTitle.indexOf(item) === index)

        if (officerTitleIds.length > 0) {
            self.props.getOfficerTitlesAsPerRulesAndExceptionOrder(uniqueOfficerTitleIds(officerTitleIds), (responseData) => {
                self.setState({ officerTitlesOrderedByRulesAndException: responseData }, () => {
                    if (self.state.isExportingWord) {
                        getExportedWordResponse(response.CommitteeMembers, self.state.officerTitlesOrderedByRulesAndException);
                        self.setState({ isExportingWord: false });
                    } else {
                        exportData(response.CommitteeMembers, self.state.officerTitlesOrderedByRulesAndException);
                    }
                })
            })
        } else if (self.state.isExportingWord) {
            getExportedWordResponse(response.CommitteeMembers, []);
            self.setState({ isExportingWord: false });
        } else {
            exportData(response.CommitteeMembers, []);
        }
    });
}
const exportData = (initCommitteeMemberList, officerTitlesOrderedByRulesAndException) => {
    let ExportedData = [];
    let dataWithOfficerTitle = [];
    let dataWithOutOfficerTitle = [];

    if (initCommitteeMemberList && initCommitteeMemberList.length > 0) {
        initCommitteeMemberList.forEach(items => {
            ExportedData.push({
                "AccountNumber": validate(items.MemberDetail_AccountNumber),
                "Name": validate(items.MemberDetail_FullName),
                "OrganizationName": validate(items.MemberDetail_FacilityName),
                "MembershipType": validate(items.MemberDetail_MemberType),
                "OfficerTitleName": validate(items.MemberDetail_OfficerTitleName),
                "Classification": validate(items.MemberDetail_Classification),
                "ClassificationDate": validate(items.MemberDetail_ClassificationDate) === '' ? '' : utilCommon.formatDateToMMDDYYYY(items.MemberDetail_ClassificationDate),
                "Vote": items.MemberDetail_Vote ? 'Yes' : 'No',
                "NoVoteReason": validate(items.MemberDetail_NoVoteReason),
                "VoteDate": validate(items.MemberDetail_VoteDate) === "" ? "" : utilCommon.formatDateToMMDDYYYY(items.MemberDetail_VoteDate),
                "JoinedDate": validate(items.MemberDetail_JoinedDate) === "" ? "" : utilCommon.formatDateToMMDDYYYY(items.MemberDetail_JoinedDate),
                "Status": validate(items.MemberDetail_MemberCommitteeStatus),
                "Address": validate(items.MemberDetail_Address),
                "City": validate(items.MemberDetail_City),
                "State": validate(items.MemberDetail_State),
                "PostalCode": validate(items.MemberDetail_PostalCode),
                "Country": validate(items.MemberDetail_Country),
                "Phone": validate(items.MemberDetail_PhoneNumber),
                "Email": validate(items.MemberDetail_Email)
            });
        });

        dataWithOfficerTitle = ExportedData.filter(item => item.MemberDetail_OfficerTitleName !== '')
        sortOfficerTitleOrderedByRulesAndException(dataWithOfficerTitle, officerTitlesOrderedByRulesAndException)
        let sortedOfficerTitles = dataWithOfficerTitle.sort((a, b) => (a.orderInex > b.orderInex) ? 1 : -1)
        dataWithOutOfficerTitle = ExportedData.filter(item => item.MemberDetail_OfficerTitleName === '')
        dataWithOutOfficerTitle.sort((a, b) => (a.MemberDetail_JoinedDate === null ? "" : a.MemberDetail_JoinedDate).localeCompare((b.MemberDetail_JoinedDate === null ? "" : b.MemberDetail_JoinedDate)));
        ExportedData = [...sortedOfficerTitles, ...dataWithOutOfficerTitle]

        if (ExportedData.length > 0) {
            self.setState({ isExportExcel: true, exportedData: ExportedData, showLoader: false }, () => resetExcelDownload());
        } else {
            self.setState({ isOpenExcelDownloadConfirmBox: true, exportedData: [], showLoader: false });
        }
    } else {
        self.setState({ isOpenExcelDownloadConfirmBox: true, exportedData: [], showLoader: false });
    }
}
export const emptyExportDataCancelHandler = () => {
    self.setState({ isOpenExcelDownloadConfirmBox: false });
}
export const emptyExportDataConfirmHandler = () => {
    self.setState({ isExportExcel: true, isOpenExcelDownloadConfirmBox: false }, () => { resetExcelDownload() });
}
const resetExcelDownload = () => {
    setTimeout(() => {
        self.setState({ isExportExcel: false });
    }, 100);
}
// End work on Excel export part :-

const sortOfficerTitleOrderedByRulesAndException = (dataWithOfficerTitle, officerTitlesOrderedByRulesAndException) => {
    (officerTitlesOrderedByRulesAndException && officerTitlesOrderedByRulesAndException.length > 0) && officerTitlesOrderedByRulesAndException.map((item, index) => item.orderInex = index + 1);
    if (officerTitlesOrderedByRulesAndException && officerTitlesOrderedByRulesAndException.length > 0) {
        dataWithOfficerTitle.map(item => {
            if (officerTitlesOrderedByRulesAndException.filter(itemInfo => itemInfo.OfficerTitleName === item.OfficerTitleName).length > 0) {
                item.orderInex = officerTitlesOrderedByRulesAndException.filter(itemInfo => itemInfo.OfficerTitleName === item.OfficerTitleName)[0].orderInex;
            } else {
                item.orderInex = officerTitlesOrderedByRulesAndException.length + 2;
            }
            return item
        })
    }
}

// Start work on Word export part :-
export const wordExport = () => {
    self.setState({ isExportingWord: true })
    excelExport();
}
// Exported Word Response in Callback : -
export const getExportedWordResponse = (initCommitteeMemberList, officerTitlesOrderedByRulesAndException) => {
    if (initCommitteeMemberList !== null && initCommitteeMemberList.length > 0) {
        getWordDocxPageResponse(initCommitteeMemberList, officerTitlesOrderedByRulesAndException);
    } else {
        self.setState({
            isWordEmpty: true,
            showLoader: false
        })
    }
}
export const getWordDocxPageResponse = (initCommitteeMemberList, officerTitlesOrderedByRulesAndException) => {
    const { initialValues: { CommitteeDesignation, CommitteeTitle } } = self.props
    let ExportedData = [];
    let dataWithOfficerTitle = [];
    let dataWithOutOfficerTitle = [];
    const fileName = CommitteeDesignation + '-Roster_' + moment(new Date()).format('MM-DD-YYYY');
    const fileDate = moment(new Date()).format('MM/DD/YYYY');
    const headerOne = 'Committee Roster Report';
    const headerTwo = [CommitteeDesignation, CommitteeTitle];
    const pageName = 'CommitteeRosterReport';
    let exportColumns = [
        { 'title': 'Officer Title', 'fieldsKey': 'MemberDetail_OfficerTitleName', style: { font: { bold: true } } },
        { 'title': 'Account', 'fieldsKey': 'MemberDetail_AccountNumber', style: { font: { bold: true } } },
        { 'title': 'Name', 'fieldsKey': 'MemberDetail_FullName', style: { font: { bold: true } } },
        { 'title': 'Company', 'fieldsKey': 'MemberDetail_FacilityName', style: { font: { bold: true } } },
        { 'title': 'City', 'fieldsKey': 'MemberDetail_City', style: { font: { bold: true } } },
        { 'title': 'State', 'fieldsKey': 'MemberDetail_State', style: { font: { bold: true } } },
        { 'title': 'Postal Code', 'fieldsKey': 'MemberDetail_PostalCode', style: { font: { bold: true } } },
        { 'title': 'Country', 'fieldsKey': 'MemberDetail_Country', style: { font: { bold: true } } },
        { 'title': 'Phone', 'fieldsKey': 'MemberDetail_PhoneNumber', style: { font: { bold: true } } },
        { 'title': 'Email', 'fieldsKey': 'MemberDetail_Email', style: { font: { bold: true } } },
        { 'title': 'Classification', 'fieldsKey': 'MemberDetail_Classification', style: { font: { bold: true } } },
        { 'title': 'Classification Assigned Date', 'fieldsKey': 'MemberDetail_ClassificationDate', style: { font: { bold: true } } },
        { 'title': 'Vote', 'fieldsKey': 'MemberDetail_Vote', style: { font: { bold: true } } },
        { 'title': 'No Vote Reason', 'fieldsKey': 'MemberDetail_NoVoteReason', style: { font: { bold: true } } },
        { 'title': 'Vote Assigned Date', 'fieldsKey': 'MemberDetail_VoteDate', style: { font: { bold: true } } },
        { 'title': 'Join Date', 'fieldsKey': 'MemberDetail_JoinedDate', style: { font: { bold: true } } },
        { 'title': 'Member Type', 'fieldsKey': 'MemberDetail_MemberType', style: { font: { bold: true } } },
        { 'title': 'Status', 'fieldsKey': 'MemberDetail_MemberCommitteeStatus', style: { font: { bold: true } } }
    ];
    initCommitteeMemberList.map(item => {
        item.MemberDetail_ClassificationDate = validate(item.MemberDetail_ClassificationDate) === '' ? '' : utilCommon.formatDateToMMDDYYYY(item.MemberDetail_ClassificationDate);
        item.MemberDetail_JoinedDate = validate(item.MemberDetail_JoinedDate) === "" ? "" : utilCommon.formatDateToMMDDYYYY(item.MemberDetail_JoinedDate);
        item.MemberDetail_VoteDate = validate(item.MemberDetail_VoteDate) === "" ? "" : utilCommon.formatDateToMMDDYYYY(item.MemberDetail_VoteDate);
        if (item.MemberDetail_OfficerTitleName) {
            dataWithOfficerTitle.push(item);
        } else {
            dataWithOutOfficerTitle.push(item);
        }
    })
    sortOfficerTitleOrderedByRulesAndException(dataWithOfficerTitle, officerTitlesOrderedByRulesAndException)
    let sortedOfficerTitles = dataWithOfficerTitle.sort((a, b) => (a.orderInex > b.orderInex) ? 1 : -1)
    dataWithOutOfficerTitle.sort((a, b) => {
        const name1 = a.MemberDetail_LastName ? a.MemberDetail_LastName.toLowerCase() : a.MemberDetail_LastName;
        const name2 = b.MemberDetail_LastName ? b.MemberDetail_LastName.toLowerCase() : b.MemberDetail_LastName;
        if (name1 < name2) { return -1; }
        if (name1 > name2) { return 1; }
        return 0;
    });
    ExportedData = [...sortedOfficerTitles, ...dataWithOutOfficerTitle]

    let docXConfig = new window.docXConfiguration(exportColumns, fileName, fileDate, headerOne, [...ExportedData], pageName, headerTwo);
    utilCommon.exportWordFile(docXConfig, [...ExportedData]);
    self.setState({ showLoader: false }) // Disable loader while file downloaded
}
export const emptyWordCancelHandler = () => {
    self.setState({
        isWordEmpty: false
    });
}
export const emptyWordConfirmHandler = () => {
    self.setState({
        isWordEmpty: false
    }, () => getWordDocxPageResponse([])); // Exported Empty File
}
// End work on Word export part :-

export const receivedCommitteeClassificationStatisticalListInformation = (filters) => {
    const { classification, vote, status_value } = filters;
    const statusValues = status_value ? status_value : `${McsStatusMasterId.ActiveCommitteeMember}`;
    if (classification !== null && vote !== null && status_value !== null) {
        lodash.debounce(onChangeFilter, 5)(classification, CommitteeRosterFilter.Classification);
        lodash.debounce(onChangeFilter, 5)(vote, CommitteeRosterFilter.Vote);
        lodash.debounce(onChangeFilter, 5)(statusValues, CommitteeRosterFilter.Status);
        return { ClassificationId: classification, Vote: vote, Status: statusValues }
    }
    return { ClassificationId: '', Vote: '', Status: '' }
}
export const showHideCommitteeOfficerModel = (isUpdated = false) => {
    let showCommitteeOfficerModel = !self.state.showCommitteeOfficerModel;
    self.setState({ showCommitteeOfficerModel })
    if (isUpdated && !showCommitteeOfficerModel) {
        self.props.getOfficerTitleOnCommittee(self.props.initialValues.CommitteeId);
        searchFilterRecord();
    } else if (!showCommitteeOfficerModel) {
        self.props.getOfficerTitleOnCommittee(self.props.initialValues.CommitteeId);

    }
}

export const refreshCommitteeDetails = () => {
    const { initialValues: { CommitteeId } } = self.props;
    self.props.getCommitteeDetails(utilCommon.encrypt(CommitteeId));
    loadData();
}
export const handleClickOk = (e) => {
    self.setState({ isShowAdministrativeAssistantPopup: false });
}
export const cancelAdministrativeAssistantOrStaffManagerPopup = () => {
    self.setState({ isShowAdministrativeAssistantOrStaffManagerPopup: false });
    checkValue = 0;
}