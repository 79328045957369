import { PAGE_CONFIG } from './navigation.models';

const { USER_PRIVILEGE } = PAGE_CONFIG;

export const API_PARAM_NULL_VALUE = ' ';

export const TASKSTATUS = {
    1: 'Open',
    2: 'Upcoming',
    3: 'Done'
}

export const EXCEPTION_STATUS_FILTERS = [
    { key: 1, text: "All", value: 1 },
    { key: 2, text: "Active", value: 2 },
    { key: 3, text: "Resolved", value: 3 }
]

// Used this model to check the previous request sent in case of confirmation popup.
export const RequestTypes = {
    'UpdateTaskDetails': 'UpdateTaskDetails',
    'UpdateTaskAssigneeStatus': 'UpdateTaskAssigneeStatus',
    'BreakDependency': 'BreakDependency',
    'UpdateCommunicationLog': 'UpdateCommunicationLog'
}

export const ItemsPerPage = [
    { key: 1, text: "25", value: 25 },
    { key: 2, text: "50", value: 50 },
    { key: 3, text: "75", value: 75 },
    { key: 4, text: "100", value: 100 }
]

export const AppGridIds = {
    "MemberGrid": 1,
    "OrganizationGrid": 2,
    "CommitteeGrid": 3,
    "CommitteeRosterGrid": 4,
    "TaskGrid": 5,
    "MembershipException": 6,
    "FeeGroupGrid": 7
}

export const minDateForAuditLogs = '01/01/1700';
export const minDateForInputDateFields = '01/01/1900';

export const endDayTime = ' 23:59:59';

export const AdvanceSearchQueryFields = {
    "IsAdvanceSearch": 'isAdvanceSearch',
    "GridId": 'gridid',
    "CommitteeDesignation": 'committeedesignation',
    "CommitteeTitle": 'committeetitle',
    "Status": 'status',
    "Classification": 'classification',
    "CommitteeType": 'committeetype',
    "CommitteeLevel": 'committeelevel',
    "FeeGroupNumber": 'feeGroupNumber',
    "FeeGroupTitle": 'feeGroupTitle',
    "PaidStatus": 'paidStatus',
    "AutoRenewal": 'autoRenewal',
    "Country": 'country',
    "ContactName": 'contactName',
    "AssociatedMemberAccountNo": 'associatedMemberAccountNo',
    "AssociatedMemberFirstName": 'associatedMemberFirstName',
    "AssociatedMemberLastName": 'associatedMemberLastName'

}

export const ReasonForUpdateMaxLength = '200';

export const CountryCodes = {
    "UNITEDSTATES": { Code: "US", Name: "United States" },
    "CANADA": { Code: "CA", Name: "Canada" }
}

export const McsStatusMasterId = {
    "ActiveCommittee": 1,
    "InactiveCommmittee": 3,
    "ActiveCommitteeMember": 11,
    "InactiveCommitteeMember": 12,
    "ActiveMember": 17,
    "HistoricalMember": 18,
    "InactiveMember": 19
}

// Add the gri columns here as needed. Do not hard code in any file
export const GridColumns = {
    Email: "Email",
    Action: "Action",
    MemberDetail: {
        Email: "MemberDetail_Email",
        MemberTypeId: "MemberDetail_MemberTypeId",
        MemberId: "MemberDetail_MemberId",
        AccountNumber: "MemberDetail_AccountNumber",
        FacilityName: "MemberDetail_FacilityName",
        OrgAccountNumber: "MemberDetail_OrgAccountNumber",
        RepMemberName: "MemberDetail_RepMemberName",
        RepAccountNumber: "MemberDetail_RepAccountNumber",
        PhoneNumber: "MemberDetail_PhoneNumber",
        OfficerTitleName: "MemberDetail_OfficerTitleName",
        Classification: "MemberDetail_Classification",
        NoVoteReason: "MemberDetail_NoVoteReason",
        Vote: "MemberDetail_Vote",
        JoinedDate: "MemberDetail_JoinedDate",
        ClassificationDate: "MemberDetail_ClassificationDate",
        VoteDate: "MemberDetail_VoteDate",
        MemberCommitteeStatus: "MemberDetail_MemberCommitteeStatus",
        McsStatusMasterId: "MemberDetail_McsStatusMasterId",
        MemberCommitteeStatusId: "MemberDetail_MemberCommitteeStatusId"
    },
    CommitteeDetail: {
        CommitteeDesignation: "CommitteeDetail_CommitteeDesignation",
        CommitteeId: "CommitteeDetail_CommitteeId",
        CommitteeClassification: "CommitteeDetail_CommitteeClassification",
        CommitteeTitle: "CommitteeDetail_CommitteeTitle",
        Status: "CommitteeDetail_Status",
        InactiveReason: "CommitteeDetail_InactiveReason",
        InactiveDate: "CommitteeDetail_InactiveDate",
        MergedCommittee: "CommitteeDetail_MergedCommittee",
        Chairman: "CommitteeDetail_Chairman",
        LevelId: "CommitteeDetail_LevelId",
        SubCommitteeChair: "CommitteeDetail_SubCommitteeChair"
    },
    ExceptionDetails: {
        AccountNumber: 'AccountNumber',
        MemberName: 'MemberName',
        OrderType: 'OrderType',
        Source: 'Source',
        ExceptionReason: 'ExceptionReason',
        MessageDescription: 'MessageDescription',
        EncounteredOn: 'EncounteredOn',
        Status: 'Status',
        ModifiedBy: 'ModifiedBy'
    }
}

// TODO : get domain prefix to set cookies doamin wise.
export const getDoaminPrefix = () => {
    const hostname = window.location.hostname;
    let prefixName = '';
    if (hostname) {
        let envPrefix = hostname.indexOf('-') > -1 ? hostname.split('-') : '';
        if (envPrefix && envPrefix.length > 0) {
            prefixName = `${envPrefix[0].toUpperCase()}`;
            if (prefixName == 'QA') {
                prefixName = 'TEST'
            }
            if (['DEV', 'TEST', 'STAGE'].includes(prefixName)) {
                prefixName = prefixName + '-';
            }
        }
    }
    return prefixName;
}


export const cookieKeysEnum = {
    'APP_SESSIONID': `${getDoaminPrefix()}MCS-AZURE-APP-SESSION-ID`,
    'accessToken': `${getDoaminPrefix()}MCS-AZURE-ACESS-TOKEN`
}

export const OfficerTitleEnum = {
    'StaffManager': 'Staff Manager',
    'AdministrativeAssistant': 'Administrative Assistant',
    'Chair': 'Chair',
    'SubChair': 'Sub Committee Chair'
}

export const ReportTypeEnum = [{ label: 'Member Facing (PDF)', value: 1, key: 1 }, { label: 'Working Schedule (PDF)', value: 2, key: 2 }];

export const ReportTypeEnumLineSchedule = [{ text: 'Select', value: '', key: '' }, { text: 'Member Facing (Excel)', value: 1, key: 1 }, { text: 'Member Facing (PDF)', value: 2, key: 2 }, { text: 'Working Schedule (PDF)', value: 3, key: 3 }];

export const ReportTypeEnumRegistrationFeesReport = [
    { text: 'Select', value: '', key: '' },
    { text: 'Detailed Meeting Report', value: 1, key: 1 },
    { text: 'Detailed Symposia Report', value: 3, key: 3 },
    { text: 'Committee Attendance Summary', value: 2, key: 2 },
    { text: 'D02 Accompanying Companion Report', value: 4, key: 4 },
    { text: 'Door Sign Report', value: 5, key: 5 }
];

export const meetingSymposiaType = { 1: 'Meeting', 3: 'Symposia' };

export const TemaplateTypeEnum = {
    blockShedule: 'block-shedule',
    lineSheduleReport: 'line-shedule-report'
};

export const S3_URL_TYPE = {
    RENEWAL_TASK_UPLOAD: 1,
    RENEWAL_TASK_DOWNLOAD: 2,
    BALLOT_ADMIN_FILE_UPLOAD: 3,
    BALLOT_ADMIN_FILE_DOWNLOAD: 4,
};

export const sourceDMS = 'dms';

export const bucketIN = 'in';

export const bucketOUT = 'out';

export const disableViewSubModulePrivilegeId = [129, 130, 131];

export const privilegeName = [USER_PRIVILEGE.VIEW];

export const D02_Committee = 'D02';

export const COMPANY_TYPE = {
    COMPANY: 1,
    FACILITY: 2
};

export const ReportTypeEnumAccountingReport = [
    { text: 'Select', value: '', key: '' },
    { text: 'Accounting Report', value: '1', key: '1' },
    { text: 'Cost Center Report', value: '2', key: '2' },
]

export const eventListParamsEnum = {
    Event_Status: '?eventstatus=',
    Event_Type: '&eventtype=',
    Meeting_Symposia: '&meetingsymposiatype=',
    Committee_Designation: '&committeedesignation=',
    From_Date: '&fromdate=',
    To_Data: '&todate='
}

export const event_Type = {
    Independent_Meeting: 'IndependentMeeting',
    Committee_Week: 'CommitteeWeek',
    Icam: 'ICAM'
}

export const meetingSymposiaEnum = {
    Meeting: 'Meeting',
    Symposia: 'Symposia'
}

export const organizationFacilityRadioText = {
    firstRadioMemOrg: 'I have the organization account number',
    secondRadioMemOrg: 'I don\'t have the organization account number',
    firstRadioFacility: 'I have the college/university account number',
    secondRadioFacility: 'I don\'t have the college/university account number'
}

export const event_Status = 'Cancelled,Live,On-Site,Pre-Event,Sold Out'

export const independentMeetingAndCommitteeWeek = `${event_Type.Independent_Meeting},${event_Type.Committee_Week}`

export const independentMeetingCommitteeWeekAndIcam = `${event_Type.Independent_Meeting},${event_Type.Committee_Week},${event_Type.Icam}`

export const meetingAndSymposia = `${meetingSymposiaEnum.Meeting},${meetingSymposiaEnum.Symposia}`

export const fee_drop_time = ' 4:30 AM '

export const invoiceReceiptEnum = [{ label: 'Membership Invoice', value: 'invoice', key: 'invoice' }, { label: 'Membership Receipt', value: 'receipt', key: 'receipt' }];

export const Invoice = 'invoice';

export const Receipt = 'receipt';

export const PendingNovoteReasonID= 1;

export const NoVoteReasonIdForEmpty=7;
